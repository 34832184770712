<template>
  <modal :is-open="isOpen" class-modal="frontend" @closeModal="closeModal">
    <template #header>
      <h3 class="text-4xl leading-snug">
        {{
          account
            ? $t('accounts.edit.updateTitle')
            : $t('accounts.edit.newTitle')
        }}
      </h3>
    </template>

    <label-field
      :label="$t('accounts.labels.firstName')"
      class="pr-0 mt-4"
      is-block
      :label-class="[]"
      required
    >
      <input
        v-model="firstName"
        type="text"
        required
        name="firstName"
        class="w-full"
      />
    </label-field>

    <label-field
      :label="$t('accounts.labels.lastName')"
      class="pr-0 mt-4"
      is-block
      :label-class="[]"
      required
    >
      <input
        v-model="lastName"
        type="text"
        required
        name="lastName"
        class="w-full"
      />
    </label-field>

    <label-field
      :label="$t('accounts.labels.email')"
      class="pr-0 mt-4"
      is-block
      :label-class="[]"
      required
    >
      <input
        v-model="email"
        type="email"
        required
        name="email"
        class="w-full"
      />
    </label-field>

    <template #footer>
      <div class="flex justify-between space-x-6">
        <button class="btn btn--secondary" @click="closeModal">
          {{ $t('general.cancel') }}
        </button>
        <button
          class="btn btn--primary"
          :disabled="$v.$invalid"
          @click="saveAccount"
        >
          {{ $t('general.save') }}
        </button>
      </div>
    </template>
  </modal>
</template>

<script>
import LabelField from '@/components/LabelField';
import Modal from '@/components/Modal';
import { required, email } from 'vuelidate/lib/validators';
import {
  updateOrgUnitUser,
  createOrgUnitUser,
} from '@/services/org-unit-user.service.js';

export default {
  components: {
    Modal,
    LabelField,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    account: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
    };
  },
  mounted() {
    if (this.account) {
      this.firstName = this.account.firstName;
      this.lastName = this.account.lastName;
      this.email = this.account.email;
    }
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  methods: {
    closeModal() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.$emit('close');
    },
    async saveAccount() {
      try {
        if (this.account) {
          // Update account
          await updateOrgUnitUser(this.account.id, {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
          });
        } else {
          // Create account
          await createOrgUnitUser({
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
          });
        }

        this.$notify({
          type: 'success',
          text: this.$t('accounts.edit.success'),
        });
        this.$emit('update');
        this.closeModal();
      } catch (e) {
        let message = '';
        if (e?.response?.data?.email?.length) {
          message = this.$t('accounts.edit.emailInUseError');
        } else {
          message = this.$t('accounts.edit.error');
        }
        this.$notify({
          type: 'error',
          text: message,
        });
      }
    },
  },
};
</script>
