<template>
  <document-layout class="frontend">
    <accounts />
  </document-layout>
</template>

<script>
import Accounts from '@/components/user/accounts/Accounts.vue';

export default {
  components: {
    Accounts,
  },
  mounted() {
    document.body.classList.add('frontend');
    document.body.classList.remove('backend');
  },
};
</script>
