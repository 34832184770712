import { userApi } from './api';

const getAllOrgUnitUsers = async (queryParams) => {
  const query = new URLSearchParams(queryParams);
  const response = await userApi.get(`account/org-user/?${query}`);

  return response.data;
};

const createOrgUnitUser = async (data) => {
  const response = await userApi.post(`account/org-user/`, data);

  return response.data;
};

const updateOrgUnitUser = async (userId, data) => {
  const response = await userApi.put(`account/org-user/${userId}/`, data);

  return response.data;
};

const updateOrgUnitUserState = async (userId) => {
  const response = await userApi.post(
    `account/org-user/${userId}/update-state`
  );

  return response.data;
};

export {
  getAllOrgUnitUsers,
  createOrgUnitUser,
  updateOrgUnitUser,
  updateOrgUnitUserState,
};
