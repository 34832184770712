<template>
  <div class="px-10 py-12 4xl:px-0 responsive-container">
    <div class="px-20 pt-10 bg-white shadow-md pb-14">
      <h1 class="text-5xl">{{ $t('accounts.title') }}</h1>

      <div class="flex justify-between mt-12">
        <div>
          <button class="flex btn btn--primary" @click="openNewAccountModal">
            <svg-icon class="w-6 h-6 mr-3 text-white" name="add-user" />
            {{ $t('accounts.newButton') }}
          </button>

          <edit-account-modal
            :is-open="isNewAccountModalOpen"
            @update="loadAccounts"
            @close="closeNewAccountModal"
          />
        </div>
      </div>

      <div class="flex mt-20">
        <search
          :placeholder="$t('accounts.searchPlaceholder')"
          @search="searchAccounts"
        />

        <admin-document-list-pagination
          ref="pagination"
          class="flex pl-8 ml-auto frontend"
          :count="accountPagination.count"
          @goto="changePage"
        />
      </div>

      <accounts-list
        :accounts="accounts"
        @sortAccounts="sortAccounts"
        @update="loadAccounts"
      />
    </div>
  </div>
</template>

<script>
import AccountsList from '@/components/user/accounts/AccountsList.vue';
import EditAccountModal from '@/components/user/accounts/EditAccountModal.vue';
import AdminDocumentListPagination from '@/components/admin/AdminDocumentListPagination.vue';
import Search from '@/components/admin/Search.vue';
import { getAllOrgUnitUsers } from '@/services/org-unit-user.service.js';

export default {
  components: {
    AccountsList,
    EditAccountModal,
    AdminDocumentListPagination,
    Search,
  },
  data() {
    return {
      isNewAccountModalOpen: false,
      accountPagination: {},
      currentPage: 1,
      sort: {},
      searchTerm: '',
    };
  },
  computed: {
    accounts() {
      return this.accountPagination?.results || [];
    },
  },
  mounted() {
    this.loadAccounts();
  },
  methods: {
    openNewAccountModal() {
      this.isNewAccountModalOpen = true;
    },
    closeNewAccountModal() {
      this.isNewAccountModalOpen = false;
    },
    async loadAccounts() {
      try {
        this.accountPagination = await getAllOrgUnitUsers({
          ...this.sort,
          search: this.searchTerm,
          page: this.currentPage,
        });
      } catch (e) {
        this.accountPagination = {};
        this.$notify({
          type: 'error',
          text: e?.response?.data?.message || this.$t('accounts.loadError'),
        });
      }
    },
    changePage(page) {
      this.currentPage = page;

      this.loadAccounts();
    },
    sortAccounts(filterKey, value) {
      this.sort[filterKey] = value;

      this.loadAccounts();
    },
    searchAccounts(searchTerm) {
      this.searchTerm = searchTerm;
      this.currentPage = 1;
      this.$refs.pagination.setCurrentPage(1);

      this.loadAccounts();
    },
  },
};
</script>
