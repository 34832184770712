<template>
  <div>
    <v-popover ref="popover" popover-class="context-menu">
      <button class="font-sansbold btn--icon-right">
        <slot />
        <svg-icon name="menu" class="text-secondary-500 svg-icon--lg" />
      </button>

      <template slot="popover">
        <div>
          <div class="context-menu--item">
            <button disabled>
              {{ $t('accounts.contextMenu.label') }}
            </button>
          </div>

          <!-- Action to enable the account -->
          <div class="context-menu--item">
            <button
              :disabled="isCurrentUser || account.isActive"
              @click="updateAccountState"
            >
              {{ $t('accounts.contextMenu.enable') }}
            </button>
          </div>

          <!-- Action to disable the account -->
          <div class="context-menu--item">
            <button
              :disabled="isCurrentUser || !account.isActive"
              @click="updateAccountState"
            >
              {{ $t('accounts.contextMenu.disable') }}
            </button>
          </div>

          <!-- Action to edit the account -->
          <div class="context-menu--item">
            <button @click="openEditAccountModal">
              {{ $t('accounts.contextMenu.edit') }}
            </button>
          </div>
        </div>
      </template>
    </v-popover>

    <edit-account-modal
      v-if="isEditAccountModalOpen"
      :is-open="isEditAccountModalOpen"
      :account="account"
      @close="closeEditAccountModal"
      @update="reloadAccounts"
    />
  </div>
</template>

<script>
import EditAccountModal from '@/components/user/accounts/EditAccountModal.vue';
import { updateOrgUnitUserState } from '@/services/org-unit-user.service.js';

export default {
  components: {
    EditAccountModal,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditAccountModalOpen: false,
    };
  },
  computed: {
    isCurrentUser() {
      return this.account.email == this.$store.state.Auth.email;
    },
  },
  methods: {
    async updateAccountState() {
      if (this.isCurrentUser) return;

      try {
        await updateOrgUnitUserState(this.account.id);
        this.$emit('update');
      } catch (e) {
        this.$notify({
          type: 'error',
          text:
            e?.response?.data?.message ||
            this.$t('accounts.contextMenu.updateStateError'),
        });
      }
    },
    openEditAccountModal() {
      this.isEditAccountModalOpen = true;
    },
    closeEditAccountModal() {
      this.isEditAccountModalOpen = false;
    },
    reloadAccounts() {
      this.$emit('update');
    },
  },
};
</script>

<style lang="postcss" scoped>
.context-menu {
  @apply bg-gray-50 !mt-[10px];
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 3px 3px;
}

.context-menu--item {
  @apply border-b border-dotted text-secondary-500 px-2 py-1 cursor-pointer;

  &:first-of-type {
    @apply border-none;
  }

  &:not(:first-of-type):hover {
    @apply bg-secondary-500 text-white;
  }
}

button {
  @apply w-full text-left;

  &[disabled] {
    @apply text-gray-400 cursor-not-allowed;
  }
}
</style>
