<template>
  <tr>
    <td>
      <div class="state" :class="{ active: account.isActive }"></div>
    </td>

    <td>
      {{ account.firstName }}
    </td>

    <td>
      {{ account.lastName }}
    </td>

    <td>
      {{ account.email }}
    </td>

    <td>
      {{ lastLogin }}
    </td>

    <td>
      <accounts-list-context-menu :account="account" @update="reloadAccounts" />
    </td>
  </tr>
</template>

<script>
import AccountsListContextMenu from '@/components/user/accounts/AccountsListContextMenu.vue';
import { formatDate } from '@/helpers/DateFormatters';

export default {
  components: {
    AccountsListContextMenu,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lastLogin() {
      return formatDate(this.account.lastLogin) || '-';
    },
  },
  methods: {
    reloadAccounts() {
      this.$emit('update');
    },
  },
};
</script>

<style lang="postcss" scoped>
.state {
  @apply w-[14px] h-[14px] mt-[5px] mr-2 rounded-full bg-primary-500;

  &.active {
    @apply bg-green-600;
  }
}
</style>
