<template>
  <div>
    <table v-if="accounts.length" class="mt-8 md:mt-10">
      <tr>
        <th>
          <div class="flex items-center">
            {{ $t('accounts.labels.isActive') }}
            <admin-sort-filter
              ref="filter_is_active"
              class="flex items-center ml-2"
              filter-key="is_active"
              @filter="sortAccounts"
            />
          </div>
        </th>

        <th>
          <div class="flex items-center">
            {{ $t('accounts.labels.firstName') }}
            <admin-sort-filter
              ref="filter_first_name"
              class="flex items-center ml-2"
              filter-key="first_name"
              @filter="sortAccounts"
            />
          </div>
        </th>

        <th>
          <div class="flex items-center">
            {{ $t('accounts.labels.lastName') }}
            <admin-sort-filter
              ref="filter_last_name"
              class="flex items-center ml-2"
              filter-key="last_name"
              @filter="sortAccounts"
            />
          </div>
        </th>

        <th class="w-1/4">
          <div class="flex items-center">
            {{ $t('accounts.labels.email') }}
            <admin-sort-filter
              ref="filter_email"
              class="flex items-center ml-2"
              filter-key="email"
              @filter="sortAccounts"
            />
          </div>
        </th>

        <th>
          <div class="flex items-center">
            {{ $t('accounts.labels.lastLogin') }}
            <admin-sort-filter
              ref="filter_last_login"
              class="flex items-center ml-2"
              filter-key="last_login"
              @filter="sortAccounts"
            />
          </div>
        </th>
        <th class="w-10"></th>
      </tr>

      <accounts-list-entry
        v-for="account in accounts"
        :key="account.id"
        :account="account"
        @update="loadAccounts"
      />
    </table>
    <p v-else class="text-base mt-16">
      {{ $t('accounts.empty') }}
    </p>
  </div>
</template>

<script>
import AccountsListEntry from '@/components/user/accounts/AccountsListEntry.vue';
import AdminSortFilter from '@/components/admin/AdminSortFilter';

const AVAILABLE_FILTERS = ['first_name', 'last_name', 'email', 'last_login'];

export default {
  components: {
    AccountsListEntry,
    AdminSortFilter,
  },
  props: {
    accounts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    sortAccounts(filterKey, value) {
      // Reset state from all other filters
      AVAILABLE_FILTERS.forEach((filter) => {
        if (filter !== filterKey) {
          this.$refs['filter_' + filter].resetState();
        }
      });

      this.$emit('sortAccounts', 'ordering', value);
    },
    loadAccounts() {
      this.$emit('update');
    },
  },
};
</script>
